import Modal from 'react-bootstrap/Modal'
import {IconCheckCircle, IconDecrement, IconExit} from '../../../../../commons/icons'
import {IAddPurchare} from '../../../../../commons/interfaces/wishList/wishListProduct'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import wishlistService from '../../../../../services/whislist'
import {toast} from 'react-toastify'
import IconErrorCircle from '../../../../../commons/icons/IconErrorCircle'
import { Product } from '../../../../../commons/interfaces/wishList/wishListShowSumary'

// Props
interface IProps {
  card: Product
  show: boolean
  onHide: () => void
  entityCustomerId: number
  refreshProduct: ({dataToUpdate, product} : { dataToUpdate: IAddPurchare, product: Product }) => void
}

/**
 * Component ModalAddProduct
 * This component is responsible for rendering the modal to add a product.
 * @param param {IProps} {card, show, onHide, entityCustomerId, refreshProduct}
 * @param param.card Card of the product
 * @param param.show Show modal
 * @param param.onHide Hide modal
 * @param param.entityCustomerId Entity customer id
 * @param param.refreshProduct Refresh product when execute mutation
 * @returns 
 * @example
 * <ModalAddProduct card={card} show={show} onHide={onHide} entityCustomerId={entityCustomerId} refreshProduct={refreshProduct} />
 */
export const ModalAddProduct = ({card, show, onHide, entityCustomerId, refreshProduct}: IProps) => {
  const [inputUND, setInputUND] = useState<string|number>(card?.quantity || "")
  const [inputMaster, setInputMaster] = useState<string|number>((card?.quantity || 0) / card?.conversion_unit!)
  const [convert, setConvert] = useState(card?.conversion_unit!)

  const whislistMutation = useMutation(wishlistService.addUpdateItemPurchase, {
    onSuccess: (resp) => {
      // Show success toast
      toast.success('El producto fue agregado al carrito', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      // Build data to update the list of products in the cart
      const dataToUpdate = {
        entity_customer_id: entityCustomerId,
        content_product_id: card?.id,
        quantity: Number(inputUND),
        selected: 1,
      }
      // Refresh the list of products in the cart
      refreshProduct({dataToUpdate, product: card});
      // Hide the modal
      onHide()
    },
    onError: (e: any) => {
      // Show error toast
      toast.error('Al parecer ocurrio un error, al agregar el producto. Intenteló más tarde.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconErrorCircle />,
        bodyClassName: 'custom-toast-error',
      })
      onHide()
    },
  })

  const handleInputChange = (event) => {
    const {name, value} = event.target
    if (name === 'inputUND') {
      setInputUND(value)
      setInputMaster(Math.round((value / convert) * 100) / 100)
    } else {
      if (inputMaster === 0) {
        setInputMaster(value)
      }
      setInputMaster(Math.round(value * 100) / 100)
      setInputUND(Math.round(value * convert))
    }
  }

  const handleIncrement = (event) => {
    if (event.target.name === 'inputUND') {
      setInputUND(Math.round(Number(inputUND)) + 1)
      setInputMaster(Math.round(((Number(inputUND) + 1) / convert) * 100) / 100)
    } else {
      setInputMaster(Math.round((Number(inputMaster) + 1) * 100) / 100)
      setInputUND(Math.round((Number(inputMaster) + 1) * convert))
    }
  }

  const handleDecrement = (event) => {
    if (event.target.name === 'inputUND') {
      if (Number(inputUND) > 0) {
        setInputUND(Number(inputUND) - 1)
        setInputMaster(Math.round(((Number(inputUND) - 1) / convert) * 100) / 100)
      }
    } else {
      if (Number(inputMaster) > 1) {
        setInputMaster(Math.round((Number(inputMaster) - 1) * 100) / 100)
        setInputUND(Math.round((Number(inputMaster) - 1) * convert))
      }
    }
  }

  const handleSubmit = () => {
    const sendBackend = [
      {
        entity_customer_id: entityCustomerId,
        content_product_id: card?.id,
        quantity: Number(inputUND),
        selected: 1,
      },
    ]

    const payload = sendBackend
    whislistMutation.mutate(payload)
    onHide()
  }

  useEffect(() => {
    // Set the values of the product in the modal
    if (show) {
      // Set the state of the quantity of the product
      setInputUND(card.quantity || "");
      // Set the state of the quantity of the product in master
      setInputMaster(card.quantity ? Math.round((card.quantity / card?.conversion_unit!) * 100) / 100 : "")
      // Set the state of the conversion of the product
      setConvert(card?.conversion_unit!)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      show={show}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <h4 className='fs-4 px-14 text-center text-neutral-800 fw-medium mt-3'>
                {card?.name}
              </h4>
              <p className='text-gray-2'>SKU: {card?.sku}</p>
              <div className='w-100 px-8 mt-10'>
                <div className='d-flex justify-content-between align-items-center mb-8'>
                  <label className='card-wishlist-title fs-6' htmlFor='inputUND'>
                    {card?.unit_measure} (UND)
                  </label>
                  <div className='counter-container'>
                    <button className='decrement-button btn-icon-manage-quanity' name='inputUND' onClick={handleDecrement}>
                      <IconDecrement stroke='#fff' />
                    </button>
                    <input
                      className='count input-count'
                      type='number'
                      name='inputUND'
                      value={inputUND}
                      onChange={handleInputChange}
                      min='0'
                      step='1'
                      onKeyDown={(event) => {
                        if (event.key === '.') {
                          event.preventDefault() // Evita el carácter "." en el input
                        }
                      }}
                    />
                    <button
                      className='increment-button text-white px-3 btn-icon-manage-quanity'
                      name='inputUND'
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center mb-8'>
                  <label className='card-wishlist-title fs-6' htmlFor='inputMaster'>
                    {card?.master_package} (MASTER)
                  </label>
                  <div className='counter-container'>
                    <button
                      className='decrement-button btn-icon-manage-quanity'
                      name='inputMaster'
                      onClick={handleDecrement}
                    >
                      <IconDecrement stroke='#fff' />
                    </button>
                    <input
                      type='number'
                      name='inputMaster'
                      className='count input-count'
                      value={inputMaster}
                      onChange={handleInputChange}
                      min='0'
                      step='1'
                    />
                    <button
                      className='increment-button text-white px-3 btn-icon-manage-quanity'
                      name='inputMaster'
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-grid gap-2 mt-5 '>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={inputUND === 0 || !inputUND}
              >
                Añadir a pre-pedido
              </button>
            </div>
          </div>

          <div onClick={onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
